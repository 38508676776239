import { useState, useEffect, Fragment } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { userService } from '../services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUser,
	faEllipsisVertical,
	faUserMinus,
} from '@fortawesome/free-solid-svg-icons';
import {
	collection,
	query,
	where,
	getDocs,
	getDoc,
	doc as firestoreDoc,
} from 'firebase/firestore';
import { db } from '../services/firebase';
import { Menu, Transition } from '@headlessui/react';

export default function Settings() {
	const { userProfile, refreshUserProfile } = useAuth();
	const [username, setUsername] = useState(userProfile?.username || '');
	const [teams, setTeams] = useState([]);
	const [teamMembers, setTeamMembers] = useState({});
	const [isEditing, setIsEditing] = useState(false);
	const [error, setError] = useState('');
	const [success, setSuccess] = useState('');
	const [loading, setLoading] = useState(true);

	// Load teams and their members
	useEffect(() => {
		const loadTeamsAndMembers = async () => {
			if (!userProfile?.id) return;

			try {
				setLoading(true);
				// Load teams first
				const userTeams = await userService.getUserTeams(
					userProfile.id
				);
				setTeams(userTeams);

				// Then load members for each team
				const membersData = {};
				for (const team of userTeams) {
					const teamMembersRef = collection(db, 'teamMembers');
					const q = query(
						teamMembersRef,
						where('teamId', '==', team.id)
					);
					const snapshot = await getDocs(q);

					const memberPromises = snapshot.docs.map(async (doc) => {
						const userId = doc.data().userId;
						const userRef = firestoreDoc(db, 'users', userId);
						const userDoc = await getDoc(userRef);

						if (userDoc.exists()) {
							return {
								id: userDoc.id,
								...userDoc.data(),
								role: doc.data().role,
							};
						}
						return null;
					});

					membersData[team.id] = (
						await Promise.all(memberPromises)
					).filter((member) => member !== null);
				}

				setTeamMembers(membersData);
			} catch (error) {
				console.error('Error loading teams and members:', error);
			} finally {
				setLoading(false);
			}
		};

		loadTeamsAndMembers();
	}, [userProfile]);

	if (loading) {
		return (
			<div className='h-[calc(100vh-4rem)] bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 flex items-center justify-center'>
				<div className='relative flex flex-col items-center'>
					<div className='w-16 h-16 border-4 border-teal-600 border-t-transparent rounded-full animate-spin'></div>
					<div className='absolute top-0 w-16 h-16 border-4 border-teal-200 rounded-full animate-pulse'></div>
					<div className='mt-6 text-teal-600 font-medium'>
						Loading settings...
					</div>
				</div>
			</div>
		);
	}

	const handleUpdateUsername = async (e) => {
		e.preventDefault();
		setError('');
		setSuccess('');

		try {
			await userService.updateUserProfile(userProfile.id, {
				...userProfile,
				username: username.trim(),
			});
			await refreshUserProfile(userProfile.id);
			setSuccess('Username updated successfully');
			setIsEditing(false);
		} catch (error) {
			setError('Failed to update username');
			console.error('Error updating username:', error);
		}
	};

	const handleDeleteTeam = async (teamId) => {
		if (
			!window.confirm(
				'Are you sure you want to delete this team? This action cannot be undone.'
			)
		) {
			return;
		}

		try {
			await userService.deleteTeam(teamId);
			setTeams(teams.filter((team) => team.id !== teamId));
			setSuccess('Team deleted successfully');
		} catch (error) {
			setError('Failed to delete team');
			console.error('Error deleting team:', error);
		}
	};

	const handleLeaveTeam = async (teamId) => {
		if (!window.confirm('Are you sure you want to leave this team?')) {
			return;
		}

		try {
			await userService.removeTeamMember(teamId, userProfile.id);
			setTeams(teams.filter((team) => team.id !== teamId));
			setSuccess('Left team successfully');
		} catch (error) {
			setError('Failed to leave team');
			console.error('Error leaving team:', error);
		}
	};

	const handleRemoveMember = async (teamId, memberId) => {
		if (
			!window.confirm(
				'Are you sure you want to remove this member from the team?'
			)
		) {
			return;
		}

		try {
			setError('');
			await userService.removeTeamMember(teamId, memberId);

			// Update local state
			setTeamMembers((prev) => ({
				...prev,
				[teamId]: prev[teamId].filter(
					(member) => member.id !== memberId
				),
			}));

			setSuccess('Member removed successfully');
		} catch (error) {
			console.error('Error removing team member:', error);
			setError('Failed to remove team member');
		}
	};

	const renderTeamMembers = (teamId, team) => {
		const members = teamMembers[teamId] || [];
		if (members.length === 0) return null;

		return (
			<div className='mt-4 border-t border-gray-200 pt-4'>
				<h4 className='text-sm font-medium text-gray-700 mb-2'>
					Team Members
				</h4>
				<div className='space-y-2'>
					{members.map((member) => (
						<div
							key={member.id}
							className='flex items-center justify-between bg-gray-50 p-2 rounded'
						>
							<div className='flex items-center space-x-3'>
								<div className='w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center'>
									<FontAwesomeIcon
										icon={faUser}
										className='text-teal-600'
									/>
								</div>
								<div>
									<div className='text-sm font-medium'>
										{member.username || member.email}
									</div>
									<div className='text-xs text-gray-500'>
										{member.email}
									</div>
								</div>
							</div>
							<div className='flex items-center gap-2'>
								<span
									className={`text-xs px-2 py-1 rounded-full ${
										member.role === 'owner'
											? 'bg-teal-100 text-teal-800'
											: 'bg-gray-200 text-gray-800'
									}`}
								>
									{member.role === 'owner'
										? 'Owner'
										: 'Member'}
								</span>

								{member.role !== 'owner' &&
									userProfile?.id === team.ownerId && (
										<Menu
											as='div'
											className='relative inline-block text-left'
										>
											<Menu.Button className='inline-flex items-center p-1 hover:bg-gray-100 rounded-full'>
												<FontAwesomeIcon
													icon={faEllipsisVertical}
													className='h-4 w-4 text-gray-400'
												/>
											</Menu.Button>

											<Transition
												as={Fragment}
												enter='transition ease-out duration-100'
												enterFrom='transform opacity-0 scale-95'
												enterTo='transform opacity-100 scale-100'
												leave='transition ease-in duration-75'
												leaveFrom='transform opacity-100 scale-100'
												leaveTo='transform opacity-0 scale-95'
											>
												<Menu.Items className='absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
													<div className='py-1'>
														<Menu.Item>
															{({ active }) => (
																<button
																	onClick={() =>
																		handleRemoveMember(
																			teamId,
																			member.id
																		)
																	}
																	className={`${
																		active
																			? 'bg-red-50 text-red-700'
																			: 'text-red-600'
																	} group flex w-full items-center px-4 py-2 text-sm`}
																>
																	<FontAwesomeIcon
																		icon={
																			faUserMinus
																		}
																		className='mr-3 h-4 w-4'
																	/>
																	Remove
																	Member
																</button>
															)}
														</Menu.Item>
													</div>
												</Menu.Items>
											</Transition>
										</Menu>
									)}
							</div>
						</div>
					))}
				</div>
			</div>
		);
	};

	const renderTeam = (team) => (
		<div
			key={team.id}
			className='bg-white/70 backdrop-blur-lg rounded-xl border border-white/20 p-6 mb-4 shadow-lg'
		>
			<h3 className='text-lg font-semibold text-gray-900'>{team.name}</h3>
			{renderTeamMembers(team.id, team)}
		</div>
	);

	return (
		<div className='max-w-4xl mx-auto p-6'>
			<h2 className='text-2xl font-bold mb-6 text-gray-900'>Settings</h2>
			<div className='space-y-6'>
				<div className='space-y-4'>{teams.map(renderTeam)}</div>
			</div>
		</div>
	);
}
