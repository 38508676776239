import { useState, useEffect } from 'react';

export default function EffortImpactMatrix({ tasks, onTaskUpdate }) {
  const [matrixTasks, setMatrixTasks] = useState({
    quickWins: [],
    strategic: [],
    fillIns: [],
    avoid: []
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      console.log('=== Matrix Debug Info ===');
      console.log('Received tasks:', tasks);
      
      if (!Array.isArray(tasks)) {
        throw new Error('Tasks is not an array');
      }

      const categorizedTasks = tasks.reduce((acc, task) => {
        console.log('\nProcessing task:', {
          id: task.id,
          title: task.title,
          effort: task.effort,
          impact: task.impact,
          category: task.category
        });

        // Validate task properties
        if (!task.id || !task.title) {
          console.warn('Task missing required properties:', task);
          return acc;
        }

        try {
          if (task.effort && task.impact) {
            console.log(`Task ${task.title} has effort: ${task.effort} and impact: ${task.impact}`);
            
            // Debug quadrant placement
            const quadrant = 
              task.impact === 'high' && task.effort === 'low' ? 'quickWins' :
              task.impact === 'high' && task.effort === 'high' ? 'strategic' :
              task.impact === 'low' && task.effort === 'low' ? 'fillIns' :
              task.impact === 'low' && task.effort === 'high' ? 'avoid' : null;

            console.log(`Placing task in quadrant: ${quadrant}`);

            if (quadrant) {
              acc[quadrant].push(task);
            }
          } else {
            console.log(`Task ${task.title} missing effort or impact values:`, {
              effort: task.effort,
              impact: task.impact
            });
          }
        } catch (err) {
          console.error('Error processing individual task:', err);
        }
        return acc;
      }, {
        quickWins: [],
        strategic: [],
        fillIns: [],
        avoid: []
      });

      console.log('\nFinal categorized tasks:', {
        quickWins: categorizedTasks.quickWins.length,
        strategic: categorizedTasks.strategic.length,
        fillIns: categorizedTasks.fillIns.length,
        avoid: categorizedTasks.avoid.length
      });

      console.log('Detailed quadrant contents:');
      Object.entries(categorizedTasks).forEach(([quadrant, tasks]) => {
        console.log(`\n${quadrant}:`, tasks.map(t => ({
          title: t.title,
          effort: t.effort,
          impact: t.impact
        })));
      });

      setMatrixTasks(categorizedTasks);
      setError(null);
    } catch (err) {
      console.error('Error in matrix task processing:', err);
      setError(err.message);
    }
  }, [tasks]);

  if (error) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-red-600 bg-red-50 p-4 rounded-lg">
          Error loading matrix: {error}
        </div>
      </div>
    );
  }

  const handleQuadrantDrop = (task, effort, impact) => {
    onTaskUpdate(task.id, { effort, impact });
  };

  const renderQuadrant = (title, tasks, description, colorClass, effort, impact) => (
    <div className={`${colorClass} backdrop-blur-sm rounded-lg flex flex-col p-4 shadow-sm hover:shadow-md transition-shadow h-full`}>
      <h3 className="font-medium text-sm text-gray-800 dark:text-gray-200">
        {title}
      </h3>
      <p className="text-[10px] text-gray-500 dark:text-gray-400 mb-2">{description}</p>
      <div className="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <div className="space-y-1.5">
          {tasks.map((task) => (
            <div
              key={task.id}
              className="p-2 rounded-md bg-white/90 dark:bg-gray-800/90 shadow-sm text-xs leading-tight"
            >
              <div className="text-gray-800 dark:text-gray-200">{task.title}</div>
              {task.category !== 'inprogress' && (
                <button 
                  onClick={() => {
                    console.log('Starting task:', task.id);
                    onTaskUpdate(task.id, { 
                      effort: effort,
                      impact: impact,
                      category: 'inprogress'
                    });
                  }}
                  className="text-blue-500 hover:text-blue-700 underline text-[10px] mt-1"
                >
                  Start task
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="h-[calc(100vh-14rem)] flex items-center justify-center">
      <div className="w-[600px] flex flex-col items-center gap-6">
        {/* Impact Label */}
        <div className="text-sm font-medium text-gray-600 ml-14">Impact</div>

        <div className="flex gap-6">
          {/* Effort Label */}
          <div className="self-center -rotate-90 text-sm font-medium text-gray-600 whitespace-nowrap">
            Effort
          </div>

          {/* Matrix Grid - Fixed size square container */}
          <div className="w-[500px] h-[500px] grid grid-cols-2 gap-3">
            {/* High Impact */}
            <div className="grid grid-rows-2 gap-3">
              {/* Each quadrant is now a square with fixed height and scrollable content */}
              <div className="h-[242px]">
                {renderQuadrant(
                  "Strategic",
                  matrixTasks.strategic,
                  "High Impact, High Effort",
                  "bg-blue-50/80 dark:bg-blue-900/20",
                  'high',
                  'high'
                )}
              </div>
              <div className="h-[242px]">
                {renderQuadrant(
                  "Backlog",
                  matrixTasks.avoid,
                  "Low Impact, High Effort",
                  "bg-red-50/80 dark:bg-red-900/20",
                  'high',
                  'low'
                )}
              </div>
            </div>
            
            {/* Low Impact */}
            <div className="grid grid-rows-2 gap-3">
              <div className="h-[242px]">
                {renderQuadrant(
                  "Quick Wins",
                  matrixTasks.quickWins,
                  "High Impact, Low Effort",
                  "bg-green-50/80 dark:bg-green-900/20",
                  'low',
                  'high'
                )}
              </div>
              <div className="h-[242px]">
                {renderQuadrant(
                  "Fill-ins",
                  matrixTasks.fillIns,
                  "Low Impact, Low Effort",
                  "bg-yellow-50/80 dark:bg-yellow-900/20",
                  'low',
                  'low'
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 