import { useState, useEffect, Fragment } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { userService } from '../services/userService';
import TaskList from './TaskList';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faTrash } from '@fortawesome/free-solid-svg-icons';

export default function TeamBoard() {
	const [teams, setTeams] = useState([]);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [showInvite, setShowInvite] = useState(false);
	const [inviteEmail, setInviteEmail] = useState('');
	const [inviteMessage, setInviteMessage] = useState('');
	const { userProfile } = useAuth();
	const [showNewTeamModal, setShowNewTeamModal] = useState(false);
	const [newTeamName, setNewTeamName] = useState('');
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deleteConfirmName, setDeleteConfirmName] = useState('');
	const location = useLocation();

	// Add minimum loading timer
	useEffect(() => {
		const minLoadingTimer = setTimeout(() => {
			if (dataLoaded) {
				setLoading(false);
			}
		}, 500);

		return () => clearTimeout(minLoadingTimer);
	}, [dataLoaded]);

	useEffect(() => {
		const loadTeams = async () => {
			if (userProfile) {
				try {
					const userTeams = await userService.getUserTeams(userProfile.id);
					console.log('Loaded teams:', userTeams);
					setTeams(userTeams);

					// Get teamId from URL
					const params = new URLSearchParams(location.search);
					const urlTeamId = params.get('teamId');

					// If there's a teamId in the URL, select that team
					if (urlTeamId) {
						const teamFromUrl = userTeams.find(t => t.id === urlTeamId);
						if (teamFromUrl) {
							setSelectedTeam(teamFromUrl);
						}
					} else if (userTeams.length > 0) {
						// Only set first team if no teamId in URL
						setSelectedTeam(userTeams[0]);
					}
					setDataLoaded(true);
				} catch (error) {
					console.error('Error loading teams:', error);
					setDataLoaded(true);
				}
			}
		};
		loadTeams();
	}, [userProfile, location.search]);

	if (loading) {
		return (
			<div className='h-[calc(100vh-4rem)] bg-gradient-to-br from-teal-50 via-emerald-50 to-cyan-100 flex items-center justify-center'>
				<div className='relative flex flex-col items-center'>
					<div className='w-16 h-16 border-4 border-teal-600 border-t-transparent rounded-full animate-spin'></div>
					<div className='absolute top-0 w-16 h-16 border-4 border-teal-200 rounded-full animate-pulse'></div>
					<div className='mt-6 text-teal-600 font-medium'>
						Loading your workspace...
					</div>
				</div>
			</div>
		);
	}

	const handleInviteMember = async (e) => {
		e.preventDefault();
		setInviteMessage('');
		try {
			await userService.addTeamMember(selectedTeam.id, inviteEmail);
			setInviteMessage(
				'Invitation sent! If the user already has an account, they will be added to the team immediately. ' +
					'Otherwise, they will be added when they sign up.'
			);
			setTimeout(() => {
				setShowInvite(false);
				setInviteEmail('');
				setInviteMessage('');
			}, 3000);
		} catch (error) {
			console.error('Error inviting member:', error);
			setInviteMessage('Failed to send invitation. Please try again.');
		}
	};

	const handleCreateTeam = async (e) => {
		e.preventDefault();
		if (!newTeamName.trim()) return;

		try {
			const newTeam = await userService.createTeam(
				newTeamName.trim(),
				userProfile.id
			);
			setTeams([...teams, newTeam]);
			setSelectedTeam(newTeam);
			setNewTeamName('');
			setShowNewTeamModal(false);
			navigate(`/?teamId=${newTeam.id}`);
		} catch (error) {
			console.error('Error creating team:', error);
		}
	};

	const handleDeleteTeam = async () => {
		if (deleteConfirmName !== selectedTeam.name) {
			return;
		}

		try {
			await userService.deleteTeam(selectedTeam.id);
			setTeams(teams.filter((t) => t.id !== selectedTeam.id));
			if (teams.length > 1) {
				setSelectedTeam(teams.find((t) => t.id !== selectedTeam.id));
			} else {
				setSelectedTeam(null);
			}
			setShowDeleteModal(false);
			setDeleteConfirmName('');
		} catch (error) {
			console.error('Error deleting team:', error);
			alert('Failed to delete team. Please try again.');
		}
	};

	const handleTeamChange = (team) => {
		setSelectedTeam(team);
		// Use navigate instead of window.history.replaceState
		if (team) {
			navigate(`/?teamId=${team.id}`, { replace: true });
		} else {
			navigate('/', { replace: true });
		}
	};

	return (
		<div className='py-2 mx-[5%]'>
			<div className='max-w-6xl mx-auto flex items-center justify-end py-2'>
				<div className='flex items-center gap-2'>
					<button
						onClick={() => setShowNewTeamModal(true)}
						className='bg-teal-600 text-white w-5 h-5 rounded-full flex items-center justify-center text-[11px] font-semibold hover:bg-teal-700 transition-colors shadow-sm hover:shadow-md'
						title='Create new team'
					>
						+
					</button>

					<div className='flex items-center gap-1'>
						<div className='relative flex items-center gap-1'>
							<select
								value={selectedTeam?.id || ''}
								onChange={(e) => {
									const team = teams.find(
										(t) => t.id === e.target.value
									);
									handleTeamChange(team);
								}}
								className='px-2 py-1 pr-6 text-[11px] rounded-md shadow-sm hover:shadow-md transition-shadow focus:outline-none focus:ring-0 appearance-none bg-[length:10px] bg-[right_0.5rem_center] bg-no-repeat'
								style={{
									backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280' stroke-width='0.8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`
								}}
							>
								{teams.map((team) => (
									<option
										key={team.id}
										value={team.id}
										className='text-gray-700'
									>
										{team.name}
									</option>
								))}
							</select>

							{selectedTeam && selectedTeam.ownerId === userProfile.id && (
								<Menu as='div' className='relative inline-block text-left'>
									<Menu.Button className='inline-flex items-center p-0.5 hover:bg-gray-100 rounded-full'>
										<FontAwesomeIcon
											icon={faEllipsisVertical}
											className='h-2.5 w-2.5 text-gray-500'
										/>
									</Menu.Button>

									<Transition
										as={Fragment}
										enter='transition ease-out duration-100'
										enterFrom='transform opacity-0 scale-95'
										enterTo='transform opacity-100 scale-100'
										leave='transition ease-in duration-75'
										leaveFrom='transform opacity-100 scale-100'
										leaveTo='transform opacity-0 scale-95'
									>
										<Menu.Items className='absolute right-0 mt-1 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50'>
											<div className='py-1'>
												<Menu.Item>
													{({ active }) => (
														<button
															onClick={() =>
																setShowDeleteModal(
																	true
																)
															}
															className={`${
																active
																	? 'bg-red-50 text-red-700'
																	: 'text-red-600'
															} group flex w-full items-center px-3 py-1.5 text-[11px]`}
														>
															<FontAwesomeIcon
																icon={
																	faTrash
																}
																className='mr-2 h-3 w-3'
															/>
															Delete Team
														</button>
													)}
												</Menu.Item>
											</div>
										</Menu.Items>
									</Transition>
								</Menu>
							)}
						</div>

						<button
							onClick={() => setShowInvite(true)}
							className='px-2 py-1 text-[11px] rounded-md bg-teal-600 text-white hover:bg-teal-700 transition-colors shadow-sm hover:shadow-md'
						>
							Invite
						</button>
					</div>
				</div>
			</div>

			{/* New Team Modal */}
			{showNewTeamModal && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-lg p-6 w-full max-w-md mx-4'>
						<div className='flex justify-between items-center mb-4'>
							<h3 className='text-lg font-semibold'>
								Create New Team
							</h3>
							<button
								onClick={() => setShowNewTeamModal(false)}
								className='text-gray-400 hover:text-gray-500'
							>
								×
							</button>
						</div>
						<form onSubmit={handleCreateTeam}>
							<input
								type='text'
								value={newTeamName}
								onChange={(e) => setNewTeamName(e.target.value)}
								placeholder='Team name'
								className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500'
								autoFocus
							/>
							<div className='flex justify-end mt-4 space-x-2'>
								<button
									type='button'
									onClick={() => setShowNewTeamModal(false)}
									className='px-4 py-2 text-gray-600 hover:text-gray-800'
								>
									Cancel
								</button>
								<button
									type='submit'
									className='px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700'
								>
									Create Team
								</button>
							</div>
						</form>
					</div>
				</div>
			)}

			{/* Invite Member Modal */}
			{showInvite && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-xl shadow-xl p-6 w-full max-w-md m-4'>
						<div className='flex justify-between items-center mb-6'>
							<h3 className='text-xl font-semibold text-gray-900'>
								Invite Member to {selectedTeam.name}
							</h3>
							<button
								onClick={() => {
									setShowInvite(false);
									setInviteMessage('');
								}}
								className='text-gray-400 hover:text-gray-500 focus:outline-none'
							>
								<svg
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							</button>
						</div>
						{inviteMessage && (
							<div
								className={`mb-4 p-3 rounded-lg text-sm ${
									inviteMessage.includes('Failed')
										? 'bg-red-50 text-red-600 border border-red-200'
										: 'bg-green-50 text-green-600 border border-green-200'
								}`}
							>
								{inviteMessage}
							</div>
						)}
						<form
							onSubmit={handleInviteMember}
							className='space-y-4'
						>
							<div>
								<label
									htmlFor='email'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Email Address
								</label>
								<input
									id='email'
									type='email'
									value={inviteEmail}
									onChange={(e) =>
										setInviteEmail(e.target.value)
									}
									placeholder='colleague@example.com'
									className='w-full px-4 py-2.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
								/>
							</div>
							<div className='flex justify-end space-x-3 mt-6'>
								<button
									type='button'
									onClick={() => {
										setShowInvite(false);
										setInviteMessage('');
									}}
									className='px-4 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
								>
									Cancel
								</button>
								<button
									type='submit'
									className='px-4 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
								>
									Send Invitation
								</button>
							</div>
						</form>
					</div>
				</div>
			)}

			{/* Delete Team Confirmation Modal */}
			{showDeleteModal && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-lg p-6 w-full max-w-md mx-4'>
						<div className='flex flex-col space-y-4'>
							<div className='flex justify-between items-center'>
								<h3 className='text-lg font-semibold text-gray-900'>
									Delete Team
								</h3>
								<button
									onClick={() => {
										setShowDeleteModal(false);
										setDeleteConfirmName('');
									}}
									className='text-gray-400 hover:text-gray-500'
								>
									×
								</button>
							</div>

							<div className='space-y-4'>
								<div className='bg-red-50 text-red-800 p-4 rounded-md'>
									<p className='font-medium'>
										⚠️ Warning: This action cannot be undone
									</p>
									<p className='mt-2 text-sm'>
										This will permanently delete the team "
										{selectedTeam.name}" and all its tasks.
										Team members will lose access to all
										team content.
									</p>
								</div>

								<div>
									<label className='block text-sm font-medium text-gray-700 mb-2'>
										Type the team name{' '}
										<span className='font-bold'>
											{selectedTeam.name}
										</span>{' '}
										to confirm:
									</label>
									<input
										type='text'
										value={deleteConfirmName}
										onChange={(e) =>
											setDeleteConfirmName(e.target.value)
										}
										placeholder={`Type "${selectedTeam.name}" to confirm`}
										className='w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-red-500'
									/>
								</div>
							</div>

							<div className='flex justify-end space-x-3 mt-4'>
								<button
									onClick={() => {
										setShowDeleteModal(false);
										setDeleteConfirmName('');
									}}
									className='px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200'
								>
									Cancel
								</button>
								<button
									onClick={handleDeleteTeam}
									disabled={
										deleteConfirmName !== selectedTeam.name
									}
									className={`px-4 py-2 text-sm font-medium text-white rounded-md ${
										deleteConfirmName === selectedTeam.name
											? 'bg-red-600 hover:bg-red-700'
											: 'bg-red-300 cursor-not-allowed'
									}`}
								>
									Delete Team
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			{selectedTeam && (
				<TaskList
					teamId={selectedTeam.id}
					team={selectedTeam}
				/>
			)}
		</div>
	);
}
