import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-regular-svg-icons';

export default function Navbar() {
  const { user, logout } = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const teamId = params.get('teamId');

  // Add debug logging
  console.log('Navbar Debug:', {
    currentPath: location.pathname,
    searchParams: location.search,
    teamId,
    showGoals: Boolean(teamId),
    rawParams: params.toString()
  });

  // Function to get current teamId from URL
  const getCurrentTeamId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('teamId');
  };

  // Get current teamId
  const currentTeamId = getCurrentTeamId();

  return (
    <nav className="bg-white/70 backdrop-blur-lg border-b border-white/10 shadow-lg sticky top-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="bg-gradient-to-r from-teal-600 to-blue-600 bg-clip-text text-transparent font-bold text-xl">
              Product Sketch
            </Link>
          </div>

          {user && (
            <div className="flex items-center space-x-4">
              <Link 
                to={currentTeamId ? `/goals?teamId=${currentTeamId}` : '#'}
                className={`text-gray-700 px-3 py-2 rounded-md text-sm font-medium transition-colors flex items-center gap-2 ${
                  !currentTeamId ? 'opacity-50 pointer-events-none' : 'hover:text-gray-900'
                } ${location.pathname === '/goals' ? 'bg-gray-100' : ''}`}
                onClick={(e) => {
                  if (!currentTeamId) {
                    e.preventDefault();
                    return;
                  }
                  console.log('Navigating to goals with teamId:', currentTeamId);
                }}
              >
                <FontAwesomeIcon icon={faFlag} className="w-4 h-4" />
                Goals {currentTeamId ? '' : '(Select a team)'}
              </Link>
              <Link 
                to={currentTeamId ? `/settings?teamId=${currentTeamId}` : '/settings'} 
                className={`text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors ${
                  location.pathname === '/settings' ? 'bg-gray-100' : ''
                }`}
              >
                Settings
              </Link>
              <button
                onClick={logout}
                className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium transition-colors"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
} 