import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import Login from './components/Login';
import TeamBoard from './components/TeamBoard';
import Navbar from './components/Navbar';
import Settings from './components/Settings';
import OnboardingScreen from './components/OnboardingScreen';
import Goals from './components/Goals';

export default function App() {
  const { user, userProfile } = useAuth();

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-100 via-emerald-50 to-cyan-200">
      <div className="min-h-screen backdrop-blur-3xl">
        <Navbar />
        <Routes>
          <Route 
            path="/login" 
            element={user ? <Navigate to="/" /> : <Login />} 
          />
          <Route 
            path="/" 
            element={
              !user 
                ? <Navigate to="/login" />
                : !userProfile?.username 
                  ? <OnboardingScreen />
                  : <TeamBoard />
            } 
          />
          <Route 
            path="/goals" 
            element={
              !user 
                ? <Navigate to="/login" />
                : !userProfile?.username 
                  ? <OnboardingScreen />
                  : <Goals teamId={new URLSearchParams(window.location.search).get('teamId')} />
            } 
          />
          <Route 
            path="/settings" 
            element={
              !user 
                ? <Navigate to="/login" />
                : !userProfile?.username 
                  ? <OnboardingScreen />
                  : <Settings />
            } 
          />
        </Routes>
      </div>
    </div>
  );
} 