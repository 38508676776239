import { doc, deleteDoc } from 'firebase/firestore';
import { db } from './firebase';

export const taskService = {
	async deleteTask(taskId) {
		try {
			const taskRef = doc(db, 'tasks', taskId);
			await deleteDoc(taskRef);
		} catch (error) {
			console.error('Error deleting task:', error);
			throw error;
		}
	},
	// ... other methods ...
};
