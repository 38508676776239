import { useEffect, useState } from 'react';
import {
	onSnapshot,
	collection,
	query,
	where,
	doc,
	updateDoc,
	addDoc,
	serverTimestamp,
	writeBatch,
} from 'firebase/firestore';
import { taskService } from '../services/taskService';
import { userService } from '../services/userService';
import TaskItem from './TaskItem';
import { useAuth } from '../contexts/AuthContext';
import { DragDropContext } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './StrictModeDroppable';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faListCheck,
	faSpinner,
	faCheckDouble,
	faFileExport,
} from '@fortawesome/free-solid-svg-icons';
import { db } from '../services/firebase';
import EffortImpactMatrix from './EffortImpactMatrix';

export default function TaskList({ teamId, team }) {
	const [tasks, setTasks] = useState([]);
	const [columns, setColumns] = useState(team?.columns || []);
	const [showNewTaskModal, setShowNewTaskModal] = useState(false);
	const [newTaskColumn, setNewTaskColumn] = useState(null);
	const [newTaskTitle, setNewTaskTitle] = useState('');
	const [newTaskNote, setNewTaskNote] = useState('');
	const [newTaskTags, setNewTaskTags] = useState('');
	const [showConfetti, setShowConfetti] = useState(false);
	const [showEmoji, setShowEmoji] = useState(false);
	const { user } = useAuth();
	const { width, height } = useWindowSize();
	const [filters, setFilters] = useState({
		tags: [],
		assignee: '',
		priority: '',
	});
	const [sortByPriority, setSortByPriority] = useState(false);
	const [newTaskDueDate, setNewTaskDueDate] = useState('');
	const [viewMode, setViewMode] = useState('list'); // 'list' or 'matrix'

	useEffect(() => {
		if (team?.columns) {
			setColumns(team.columns);
		}
	}, [team]);

	useEffect(() => {
		if (!teamId) return;

		console.log('Setting up task listener for team:', teamId);
		const q = query(collection(db, 'tasks'), where('teamId', '==', teamId));

		const unsubscribe = onSnapshot(q, (snapshot) => {
			const taskList = snapshot.docs.map((docSnapshot) => {
				const data = docSnapshot.data();
				// Make sure we initialize tags as an empty array if it doesn't exist
				if (!data.tags) {
					const taskRef = doc(db, 'tasks', docSnapshot.id);
					updateDoc(taskRef, { tags: [] });
					data.tags = [];
				}
				return {
					id: docSnapshot.id,
					...data,
					tags: data.tags || [], // Ensure tags is always an array
				};
			});
			console.log('Loaded tasks:', taskList);
			setTasks(taskList);
		});

		return () => unsubscribe();
	}, [teamId]);

	const handleAddTask = async (e) => {
		e.preventDefault();
		if (!newTaskTitle.trim()) return;

		try {
			// Process tags from newTaskTags
			const tagList = newTaskTags
				.split(' ')
				.filter((tag) => tag.trim())
				.map((tag) => (tag.startsWith('#') ? tag : `#${tag}`));

			// Create new task document
			const taskRef = await addDoc(collection(db, 'tasks'), {
				title: newTaskTitle.trim(),
				category: newTaskColumn,
				createdBy: user.uid,
				teamId: teamId,
				createdAt: serverTimestamp(),
				status: 'pending',
				tags: tagList, // Add the processed tags here
				note: newTaskNote.trim(),
				replies: [],
				dueDate: newTaskDueDate || null,
			});

			// Update local state
			setTasks((prevTasks) => [
				...prevTasks,
				{
					id: taskRef.id,
					title: newTaskTitle.trim(),
					category: newTaskColumn,
					createdBy: user.uid,
					teamId: teamId,
					createdAt: new Date(),
					status: 'pending',
					tags: tagList,
					note: newTaskNote.trim(),
					replies: [],
					dueDate: newTaskDueDate || null,
				},
			]);

			// Reset form
			setShowNewTaskModal(false);
			setNewTaskTitle('');
			setNewTaskNote('');
			setNewTaskTags('');
			setNewTaskDueDate('');
			setNewTaskColumn(null);
		} catch (error) {
			console.error('Error creating task:', error);
		}
	};

	const handleColumnNameChange = async (columnId, newName) => {
		const newColumns = columns.map((col) =>
			col.id === columnId ? { ...col, name: newName } : col
		);
		setColumns(newColumns);
		try {
			await userService.updateTeamColumns(teamId, newColumns);
		} catch (error) {
			console.error('Error updating column name:', error);
		}
	};

	const handleDragEnd = async (result) => {
		const { source, destination, draggableId } = result;

		if (!destination) return;

		try {
			// Get tasks in the destination column
			const columnTasks = tasks.filter(
				(t) => t.category === destination.droppableId
			);

			// Remove the task from its current position
			const taskToMove = tasks.find((t) => t.id === draggableId);
			const remainingTasks = columnTasks.filter(
				(t) => t.id !== draggableId
			);

			// Create new array with task in new position
			const reorderedTasks = [
				...remainingTasks.slice(0, destination.index),
				taskToMove,
				...remainingTasks.slice(destination.index),
			];

			// Update all tasks with new order
			const batch = writeBatch(db);
			reorderedTasks.forEach((task, index) => {
				const taskRef = doc(db, 'tasks', task.id);
				batch.update(taskRef, {
					order: index,
					category: destination.droppableId,
					updatedAt: serverTimestamp(),
				});
			});

			// Update UI immediately with all tasks
			const newTasks = tasks.map((t) => {
				const reorderedTask = reorderedTasks.find(
					(rt) => rt.id === t.id
				);
				if (reorderedTask) {
					return {
						...t,
						order: reorderedTasks.indexOf(reorderedTask),
						category: destination.droppableId,
					};
				}
				return t;
			});

			setTasks(newTasks);
			await batch.commit();

			// Keep animations
			if (source.droppableId !== destination.droppableId) {
				if (destination.droppableId === 'done') {
					setShowConfetti(true);
					setTimeout(() => setShowConfetti(false), 3000);
				}
				if (destination.droppableId === 'inprogress') {
					setShowEmoji(true);
					setTimeout(() => setShowEmoji(false), 2000);
				}
			}
		} catch (error) {
			console.error('Error updating task positions:', error);
		}
	};

	const getColumnHeaderColor = (columnId) => {
		switch (columnId) {
			case 'todo':
				return 'bg-blue-500 rounded-t-lg';
			case 'inprogress':
				return 'bg-yellow-500 rounded-t-lg';
			case 'done':
				return 'bg-green-500 rounded-t-lg';
			default:
				return 'bg-gray-500';
		}
	};

	const getColumnTitle = (columnId) => {
		switch (columnId) {
			case 'todo':
				return (
					<>
						<FontAwesomeIcon
							icon={faListCheck}
							className='mr-2'
						/>{' '}
						To Do
					</>
				);
			case 'inprogress':
				return (
					<>
						<FontAwesomeIcon
							icon={faSpinner}
							className='mr-2'
						/>{' '}
						In Progress
					</>
				);
			case 'done':
				return (
					<>
						<FontAwesomeIcon
							icon={faCheckDouble}
							className='mr-2'
						/>{' '}
						Done
					</>
				);
			default:
				return columnId;
		}
	};

	// const getPriorityOrder = (priority) => {
	// 	switch (priority) {
	// 		case 'high':
	// 			return 3;
	// 		case 'medium':
	// 			return 2;
	// 		case 'low':
	// 			return 1;
	// 		default:
	// 			return 0;
	// 	}
	// };

	const getFilteredTasks = (tasks, column) => {
		return tasks
			.filter((task) => task.category === column.id)
			.filter((task) => !task.hidden)
			.filter((task) => {
				if (filters.tags.length > 0) {
					const taskTags = task.tags || [];
					return filters.tags.some((tag) => taskTags.includes(tag));
				}
				return true;
			})
			.filter((task) => {
				if (filters.assignee) {
					return task.assignedTo === filters.assignee;
				}
				return true;
			})
			.sort((a, b) => (a.order || 0) - (b.order || 0));
	};

	const allTags = [...new Set(tasks.flatMap((task) => task.tags || []))];
	const allAssignees = [
		...new Set(tasks.map((task) => task.assignedTo).filter(Boolean)),
	];
	const priorities = ['high', 'medium', 'low'];

	const handleTaskUpdate = async (taskId, { effort, impact }) => {
		try {
			const taskRef = doc(db, 'tasks', taskId);
			await updateDoc(taskRef, {
				effort,
				impact,
				category: 'inprogress', // Move task to In Progress when added to matrix
			});
			// Switch back to list view after starting a task
			setViewMode('list');
		} catch (error) {
			console.error('Error updating task:', error);
		}
	};

	const handleExportTasks = () => {
		// Create markdown content
		const markdown = tasks.reduce((content, task) => {
			return content + `- [ ] ${task.title}\n`;
		}, `# ${team.name} Tasks\n\n`);

		// Create and download the file
		const blob = new Blob([markdown], { type: 'text/markdown' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = `${team.name
			.toLowerCase()
			.replace(/\s+/g, '-')}-tasks.md`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	if (!team) {
		return <div>Loading board...</div>;
	}

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			{showConfetti && (
				<Confetti
					width={width}
					height={height}
					recycle={false}
					numberOfPieces={200}
					gravity={0.3}
				/>
			)}

			{showEmoji && (
				<div
					className='fixed top-1/5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-6xl animate-ping z-20'
					style={{ pointerEvents: 'none' }}
				>
					💪
				</div>
			)}

			<div className='max-w-6xl mx-auto py-2 mb-4'>
				<div className='flex gap-2 items-center justify-between'>
					{/* Left side: View toggle and Export */}
					<div className='flex gap-2'>
						<button
							onClick={() =>
								setViewMode(
									viewMode === 'list' ? 'matrix' : 'list'
								)
							}
							className='px-2 py-1 text-[11px] rounded-md bg-teal-600 text-white hover:bg-teal-700 transition-colors'
						>
							{viewMode === 'list'
								? 'Switch to Matrix'
								: 'Switch to List'}
						</button>

						<button
							onClick={handleExportTasks}
							className='px-2 py-1 text-[11px] rounded-md bg-gray-600 text-white hover:bg-gray-700 transition-colors flex items-center gap-1'
							title='Export tasks as markdown'
						>
							<FontAwesomeIcon
								icon={faFileExport}
								className='w-3 h-3'
							/>
							<span>Export</span>
						</button>
					</div>

					{/* Right side: Filters */}
					<div className='flex gap-1 items-center'>
						{/* Tags dropdown */}
						<select
							value={filters.tags}
							onChange={(e) =>
								setFilters((prev) => ({
									...prev,
									tags: [e.target.value],
								}))
							}
							className='px-2 py-1 pr-6 text-[11px] rounded-md shadow-sm hover:shadow-md transition-shadow focus:outline-none focus:ring-0 appearance-none bg-[length:10px] bg-[right_0.5rem_center] bg-no-repeat'
							style={{
								backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280' stroke-width='0.8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
							}}
						>
							<option
								value=''
								disabled={true}
							>
								Tags
							</option>
							{allTags.map((tag) => (
								<option
									key={tag}
									value={tag}
								>
									{tag}
								</option>
							))}
						</select>

						{/* Assignee dropdown */}
						<select
							value={filters.assignee}
							onChange={(e) =>
								setFilters((prev) => ({
									...prev,
									assignee: e.target.value,
								}))
							}
							className='px-2 py-1 pr-6 text-[11px] rounded-md shadow-sm hover:shadow-md transition-shadow focus:outline-none focus:ring-0 appearance-none bg-[length:10px] bg-[right_0.5rem_center] bg-no-repeat'
							style={{
								backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280' stroke-width='0.8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
							}}
						>
							<option
								value=''
								disabled={true}
							>
								Assignee
							</option>
							{allAssignees.map((assignee) => (
								<option
									key={assignee}
									value={assignee}
								>
									{assignee}
								</option>
							))}
						</select>

						{/* Priority dropdown */}
						<select
							value={filters.priority}
							onChange={(e) =>
								setFilters((prev) => ({
									...prev,
									priority: e.target.value,
								}))
							}
							className='px-2 py-1 pr-6 text-[11px] rounded-md shadow-sm hover:shadow-md transition-shadow focus:outline-none focus:ring-0 appearance-none bg-[length:10px] bg-[right_0.5rem_center] bg-no-repeat'
							style={{
								backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280' stroke-width='0.8'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
							}}
						>
							<option
								value=''
								disabled={true}
							>
								Priority
							</option>
							{priorities.map((priority) => (
								<option
									key={priority}
									value={priority}
								>
									{priority.charAt(0).toUpperCase() +
										priority.slice(1)}
								</option>
							))}
						</select>

						{/* Clear filters button */}
						{(filters.tags.length > 0 ||
							filters.assignee ||
							filters.priority) && (
							<button
								onClick={() =>
									setFilters({
										tags: [],
										assignee: '',
										priority: '',
									})
								}
								className='px-2 py-1 text-[11px] text-teal-600 hover:text-teal-800'
							>
								Clear
							</button>
						)}
					</div>
				</div>
			</div>

			<div className='h-[calc(95vh-13rem)] overflow-hidden'>
				<div className='flex h-full gap-4 max-w-6xl mx-auto'>
					{/* To Do Column - Always visible */}
					<div className='w-1/3 flex-shrink-0'>
						<div className='flex-1 flex flex-col h-full rounded-lg bg-gray-50 dark:bg-dark-glass bg-opacity-50'>
							<div
								className={`${getColumnHeaderColor(
									'todo'
								)} px-4 py-3 flex justify-between items-center`}
							>
								<span className='text-sm text-white'>
									To Do
								</span>
								<button
									onClick={() => {
										setNewTaskColumn('todo');
										setShowNewTaskModal(true);
									}}
									className='text-white hover:text-gray-200 text-md'
								>
									+
								</button>
							</div>
							<div className='flex-1 overflow-y-auto p-3 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent hover:scrollbar-thumb-gray-300'>
								<StrictModeDroppable droppableId='todo'>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
											className={`space-y-2 min-h-full ${
												snapshot.isDraggingOver
													? 'bg-blue-50 dark:bg-blue-900/20 bg-opacity-50'
													: ''
											}`}
										>
											{getFilteredTasks(tasks, {
												id: 'todo',
											}).map((task, index) => (
												<TaskItem
													key={task.id}
													task={task}
													index={index}
													projectId={teamId}
												/>
											))}
											{provided.placeholder}
										</div>
									)}
								</StrictModeDroppable>
							</div>
						</div>
					</div>

					{/* Matrix or Columns View */}
					<div className='w-2/3'>
						{viewMode === 'matrix' ? (
							<EffortImpactMatrix
								tasks={tasks.filter((task) => {
									const isNotTodo = task.category !== 'todo';
									const hasEffortImpact =
										task.effort && task.impact;

									// Apply the same filters as list view
									const matchesTags =
										filters.tags.length === 0 ||
										(task.tags &&
											filters.tags.some((tag) =>
												task.tags.includes(tag)
											));
									const matchesAssignee =
										!filters.assignee ||
										task.assignedTo === filters.assignee;
									const matchesPriority =
										!filters.priority ||
										task.priority === filters.priority;

									return (
										!isNotTodo &&
										hasEffortImpact &&
										matchesTags &&
										matchesAssignee &&
										matchesPriority
									);
								})}
								onTaskUpdate={handleTaskUpdate}
							/>
						) : (
							<div className='flex gap-4 h-full'>
								{columns
									.filter((column) => column.id !== 'todo')
									.map((column) => (
										<div
											key={column.id}
											className='flex-1 flex flex-col h-full rounded-lg bg-gray-50 dark:bg-dark-glass bg-opacity-50'
										>
											<div
												className={`${getColumnHeaderColor(
													column.id
												)} px-4 py-3 flex justify-between items-center`}
											>
												<span className='text-sm text-white'>
													{getColumnTitle(column.id)}
												</span>
												<button
													onClick={() => {
														setNewTaskColumn(
															column.id
														);
														setShowNewTaskModal(
															true
														);
													}}
													className='text-white hover:text-gray-200 text-md'
												>
													+
												</button>
											</div>
											<div className='flex-1 overflow-y-auto p-3 scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-transparent hover:scrollbar-thumb-gray-300'>
												<StrictModeDroppable
													droppableId={column.id}
												>
													{(provided, snapshot) => (
														<div
															ref={
																provided.innerRef
															}
															{...provided.droppableProps}
															className={`space-y-2 min-h-full ${
																snapshot.isDraggingOver
																	? 'bg-blue-50 bg-opacity-50'
																	: ''
															}`}
														>
															{getFilteredTasks(
																tasks,
																column
															).map(
																(
																	task,
																	index
																) => (
																	<TaskItem
																		key={
																			task.id
																		}
																		task={
																			task
																		}
																		index={
																			index
																		}
																		projectId={
																			teamId
																		}
																	/>
																)
															)}
															{
																provided.placeholder
															}
														</div>
													)}
												</StrictModeDroppable>
											</div>
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			</div>

			{/* New Task Modal */}
			{showNewTaskModal && (
				<div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
					<div className='bg-white rounded-xl shadow-xl p-6 w-full max-w-md m-4'>
						<div className='flex justify-between items-center mb-6'>
							<h3 className='text-xl font-semibold text-gray-900'>
								Create New Task
							</h3>
							<button
								onClick={() => {
									setShowNewTaskModal(false);
									setNewTaskTitle('');
									setNewTaskNote('');
									setNewTaskTags('');
									setNewTaskDueDate('');
								}}
								className='text-gray-400 hover:text-gray-500 focus:outline-none'
							>
								<svg
									className='h-6 w-6'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth='2'
										d='M6 18L18 6M6 6l12 12'
									/>
								</svg>
							</button>
						</div>
						<form
							onSubmit={handleAddTask}
							className='space-y-4'
						>
							<div>
								<label
									htmlFor='title'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Task Title
								</label>
								<input
									id='title'
									type='text'
									value={newTaskTitle}
									onChange={(e) =>
										setNewTaskTitle(e.target.value)
									}
									placeholder='Enter task title'
									className='w-full px-4 py-2.5 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
									required
								/>
							</div>
							<div>
								<label
									htmlFor='tags'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Tags
								</label>
								<input
									id='tags'
									type='text'
									value={newTaskTags}
									onChange={(e) =>
										setNewTaskTags(e.target.value)
									}
									placeholder='Add tags (e.g., #frontend #urgent)'
									className='w-full px-4 py-2.5 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
								/>
								<p className='mt-1 text-xs text-gray-500'>
									Separate tags with spaces. # will be added
									automatically if missing.
								</p>
							</div>
							<div>
								<label
									htmlFor='note'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Note (optional)
								</label>
								<textarea
									id='note'
									value={newTaskNote}
									onChange={(e) =>
										setNewTaskNote(e.target.value)
									}
									placeholder='Add a note...'
									rows='3'
									className='w-full px-4 py-2.5 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
								/>
							</div>
							<div>
								<label
									htmlFor='dueDate'
									className='block text-sm font-medium text-gray-700 mb-1'
								>
									Due Date
								</label>
								<input
									id='dueDate'
									type='date'
									value={newTaskDueDate}
									onChange={(e) =>
										setNewTaskDueDate(e.target.value)
									}
									className='text-sm text-gray-700 border border-gray-700 rounded-lg px-4 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent'
								/>
							</div>
							<div className='flex justify-end space-x-3 mt-6'>
								<button
									type='button'
									onClick={() => {
										setShowNewTaskModal(false);
										setNewTaskTitle('');
										setNewTaskNote('');
										setNewTaskTags('');
										setNewTaskDueDate('');
									}}
									className='px-4 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500'
								>
									Cancel
								</button>
								<button
									type='submit'
									className='px-4 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
								>
									Create Task
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
		</DragDropContext>
	);
}
