import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { goalService } from '../services/goalService';

export default function NewGoalModal({ isOpen, onClose, onSuccess, teamId }) {
  const { userProfile } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState('');
  const [steps, setSteps] = useState([{ title: '', description: '' }]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title.trim() || !deadline || steps.length === 0) return;

    try {
      const goalData = {
        title: title.trim(),
        description: description.trim(),
        deadline,
        teamId,
        userId: userProfile.id,
        steps: steps.filter(step => step.title.trim())
      };

      await goalService.createGoal(goalData);
      
      // Reset form
      setTitle('');
      setDescription('');
      setDeadline('');
      setSteps([{ title: '', description: '' }]);
      
      // Call onSuccess instead of onClose
      onSuccess();
    } catch (error) {
      console.error('Error creating goal:', error);
    }
  };

  const addStep = () => {
    setSteps([...steps, { title: '', description: '' }]);
  };

  const removeStep = (index) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  const updateStep = (index, field, value) => {
    const newSteps = [...steps];
    newSteps[index] = { ...newSteps[index], [field]: value };
    setSteps(newSteps);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white/80 backdrop-blur-lg rounded-lg p-6 w-full max-w-2xl mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-800">Create New Goal</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">×</button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Goal Title */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Goal Title
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
              placeholder="Enter goal title"
              required
            />
          </div>

          {/* Goal Description */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
              placeholder="Describe your goal"
              rows={3}
            />
          </div>

          {/* Deadline */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Deadline
            </label>
            <input
              type="date"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
              required
            />
          </div>

          {/* Steps */}
          <div>
            <div className="flex justify-between items-center mb-2">
              <label className="block text-sm font-medium text-gray-700">
                Steps to Achieve Goal
              </label>
              <button
                type="button"
                onClick={addStep}
                className="text-sm text-teal-600 hover:text-teal-700"
              >
                + Add Step
              </button>
            </div>
            <div className="space-y-3">
              {steps.map((step, index) => (
                <div key={index} className="flex gap-2">
                  <div className="flex-1">
                    <input
                      type="text"
                      value={step.title}
                      onChange={(e) => updateStep(index, 'title', e.target.value)}
                      placeholder={`Step ${index + 1}`}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => removeStep(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <div className="flex justify-end gap-2 pt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm bg-teal-600 text-white rounded-md hover:bg-teal-700"
            >
              Create Goal
            </button>
          </div>
        </form>
      </div>
    </div>
  );
} 