import { Handle, Position } from 'reactflow';

export default function GoalNode({ data }) {
  return (
    <div className="px-6 py-4 rounded-xl bg-teal-50 border-2 border-teal-200 shadow-lg min-w-[200px]">
      <Handle
        type="source"
        position={Position.Bottom}
        className="!bg-teal-400 !w-3 !h-3"
      />
      
      <div className="flex flex-col items-center text-center">
        <h3 className="font-semibold text-lg text-teal-900">{data.title}</h3>
        {data.description && (
          <p className="text-sm text-teal-700 mt-1">{data.description}</p>
        )}
        <div className="text-xs text-teal-600 mt-2">
          Due: {new Date(data.deadline).toLocaleDateString()}
        </div>
        <div className="w-full bg-teal-100 rounded-full h-1.5 mt-3">
          <div 
            className="bg-teal-600 h-1.5 rounded-full transition-all duration-500"
            style={{ width: `${data.progress}%` }}
          />
        </div>
      </div>
    </div>
  );
} 