import { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faPencil, faCalendar, faComment } from '@fortawesome/free-solid-svg-icons';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { createPortal } from 'react-dom';

export default function GoalStepNode({ data, id }) {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(data.label);
  const [dueDate, setDueDate] = useState(data.dueDate || '');
  const [isCompleted, setIsCompleted] = useState(data.status === 'completed');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(data.label);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (data.note) {
      setNote(data.note);
    }
  }, [data.note]);

  const handleSave = async () => {
    try {
      // Update both goal step and task
      if (data.taskId && data.goalId) {
        const taskRef = doc(db, 'tasks', data.taskId);
        
        // Update task
        await updateDoc(taskRef, {
          title: title,
          dueDate: dueDate,
          updatedAt: new Date()
        });

        // Update goal step using the callback
        if (data.onUpdate) {
          await data.onUpdate({
            title: title,
            dueDate: dueDate
          });
        }
      }
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating node:', error);
    }
  };

  const handleTitleSave = async () => {
    if (!editedTitle.trim() || editedTitle === data.label) {
      setIsEditingTitle(false);
      setEditedTitle(data.label);
      return;
    }

    try {
      if (data.taskId && data.goalId) {
        const taskRef = doc(db, 'tasks', data.taskId);
        await updateDoc(taskRef, {
          title: editedTitle.trim(),
          updatedAt: new Date()
        });

        // Update goal step using the callback
        if (data.onUpdate) {
          await data.onUpdate({
            title: editedTitle.trim()
          });
        }
      }
      setIsEditingTitle(false);
    } catch (error) {
      console.error('Error updating title:', error);
      setEditedTitle(data.label);
      setIsEditingTitle(false);
    }
  };

  const handleNoteSave = async () => {
    try {
      if (data.taskId && data.goalId) {
        const taskRef = doc(db, 'tasks', data.taskId);
        await updateDoc(taskRef, {
          note: note.trim(),
          updatedAt: new Date()
        });

        // Update goal step using the callback
        if (data.onUpdate) {
          await data.onUpdate({
            note: note.trim()
          });
        }
      }
      setShowNoteModal(false);
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const handleNoteClick = () => {
    console.log('Opening note modal with data:', {
      taskId: data.taskId,
      goalId: data.goalId,
      currentNote: note,
      dataNote: data.note
    });
    setShowNoteModal(true);
  };

  // Update title when data changes
  useEffect(() => {
    setTitle(data.label);
    setDueDate(data.dueDate || '');
  }, [data.label, data.dueDate]);

  const handleComplete = async () => {
    try {
      const newStatus = !isCompleted;
      setIsCompleted(newStatus);
      
      if (data.taskId) {
        const taskRef = doc(db, 'tasks', data.taskId);
        await updateDoc(taskRef, {
          status: newStatus ? 'completed' : 'pending',
          updatedAt: new Date()
        });
      }
    } catch (error) {
      console.error('Error updating completion status:', error);
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // If it's today
    if (d.toDateString() === today.toDateString()) {
      return 'Today';
    }
    // If it's tomorrow
    if (d.toDateString() === tomorrow.toDateString()) {
      return 'Tomorrow';
    }
    // Otherwise return the date
    return d.toLocaleDateString();
  };

  // Render modal in a portal
  const renderNoteModal = () => {
    if (!showNoteModal) return null;

    // Find the closest portal container
    const portalContainer = document.querySelector('.portal-container');
    if (!portalContainer) return null;

    return createPortal(
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-[1000]">
        <div className="bg-white/80 backdrop-blur-lg rounded-lg p-6 w-full max-w-md mx-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-800">Task Note</h3>
            <button 
              onClick={() => setShowNoteModal(false)}
              className="text-gray-400 hover:text-gray-500"
            >
              ×
            </button>
          </div>
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
            rows={4}
            placeholder="Add a note..."
          />
          <div className="flex justify-end gap-2 mt-4">
            <button
              onClick={() => setShowNoteModal(false)}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              onClick={handleNoteSave}
              className="px-4 py-2 text-sm bg-teal-600 text-white rounded-md hover:bg-teal-700"
            >
              Save Note
            </button>
          </div>
        </div>
      </div>,
      portalContainer
    );
  };

  return (
    <>
      <div className={`px-4 py-2 rounded-lg border shadow-sm ${
        data.status === 'completed' ? 'bg-green-100 border-green-200 text-green-800' :
        data.status === 'in-progress' ? 'bg-yellow-100 border-yellow-200 text-yellow-800' :
        'bg-gray-100 border-gray-200 text-gray-800'
      }`}>
        <Handle
          type="target"
          position={Position.Left}
          className="!bg-gray-400 !w-2 !h-2"
        />
        
        <div className="flex items-center gap-2">
          {/* Completion Checkbox */}
          <button
            onClick={handleComplete}
            className={`w-5 h-5 rounded border ${
              isCompleted 
                ? 'bg-green-500 border-green-600 text-white' 
                : 'border-gray-400 hover:border-gray-600'
            } flex items-center justify-center`}
          >
            {isCompleted && <FontAwesomeIcon icon={faCheck} className="w-3 h-3" />}
          </button>

          <div className="flex-1">
            {isEditing ? (
              <div className="space-y-2">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full px-2 py-1 text-sm border rounded"
                  autoFocus
                />
                <input
                  type="date"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                  className="w-full px-2 py-1 text-sm border rounded"
                />
                <div className="flex justify-end gap-2">
                  <button
                    onClick={() => setIsEditing(false)}
                    className="text-xs text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    className="text-xs text-blue-600 hover:text-blue-800"
                  >
                    Save
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {isEditingTitle ? (
                  <input
                    type="text"
                    value={editedTitle}
                    onChange={(e) => setEditedTitle(e.target.value)}
                    onBlur={handleTitleSave}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleTitleSave();
                      } else if (e.key === 'Escape') {
                        setIsEditingTitle(false);
                        setEditedTitle(data.label);
                      }
                    }}
                    className="w-full px-2 py-1 text-sm border rounded bg-white focus:outline-none focus:ring-1 focus:ring-blue-500"
                    autoFocus
                  />
                ) : (
                  <div 
                    className={`font-medium text-sm cursor-pointer hover:text-gray-600 ${isCompleted ? 'line-through' : ''}`}
                    onClick={() => setIsEditingTitle(true)}
                  >
                    {data.label}
                  </div>
                )}
                {dueDate && (
                  <div className={`text-xs mt-1 flex items-center gap-1 ${
                    new Date(dueDate) < new Date() && !isCompleted ? 'text-red-600' : 'text-gray-600'
                  }`}>
                    <FontAwesomeIcon icon={faCalendar} className="w-3 h-3" />
                    {formatDate(dueDate)}
                  </div>
                )}
                {data.note && (
                  <div className="text-xs text-gray-500 mt-1 flex items-center gap-1">
                    <FontAwesomeIcon icon={faComment} className="w-3 h-3" />
                    <span className="truncate">{data.note}</span>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex items-center gap-2">
            {!isEditing && !isEditingTitle && (
              <div className="flex flex-col gap-2">
                <button
                  onClick={handleNoteClick}
                  className="text-gray-400 hover:text-gray-600"
                  title="Add/Edit Note"
                >
                  <FontAwesomeIcon icon={faComment} className="w-3 h-3" />
                </button>
                <button
                  onClick={() => setIsEditing(true)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <FontAwesomeIcon icon={faPencil} className="w-3 h-3" />
                </button>
              </div>
            )}
          </div>
        </div>

        <Handle
          type="source"
          position={Position.Right}
          className="!bg-gray-400 !w-2 !h-2"
        />
      </div>

      {renderNoteModal()}
    </>
  );
} 