import { useState, useEffect } from 'react';
import { goalService } from '../services/goalService';
import { useAuth } from '../contexts/AuthContext';
import ReactFlow, { Handle, Position, applyNodeChanges, applyEdgeChanges, addEdge } from 'reactflow';
import 'reactflow/dist/style.css';
import NewGoalModal from './NewGoalModal';
import GoalStepNode from './GoalStepNode';
import GoalNode from './GoalNode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faTrash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Menu, Transition, Switch } from '@headlessui/react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { query, where, getDocs, writeBatch } from 'firebase/firestore';

const nodeTypes = {
  goalStep: GoalStepNode,
  goalMain: GoalNode,
};

export default function Goals({ teamId }) {
  const [goals, setGoals] = useState([]);
  const [showNewGoalModal, setShowNewGoalModal] = useState(false);
  const { userProfile } = useAuth();
  const [loading, setLoading] = useState(true);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState(null);
  const [nodesMap, setNodesMap] = useState({});
  const [edgesMap, setEdgesMap] = useState({});
  const [showAddNodeModal, setShowAddNodeModal] = useState(false);
  const [selectedGoalForNode, setSelectedGoalForNode] = useState(null);
  const [showAsTasks, setShowAsTasks] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [nodes, setNodes] = useState({});
  const [edges, setEdges] = useState({});

  const loadGoals = async () => {
    if (!teamId) return;
    try {
      const teamGoals = await goalService.getTeamGoals(teamId);
      setGoals(teamGoals);
    } catch (error) {
      console.error('Error loading goals:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadGoals();
  }, [teamId]);

  useEffect(() => {
    const newNodesMap = {};
    const newEdgesMap = {};

    goals.forEach(goal => {
      const mainNode = {
        id: `goal-${goal.id}`,
        type: 'goalMain',
        data: { 
          title: goal.title,
          description: goal.description,
          deadline: goal.deadline,
          progress: goal.progress
        },
        position: { x: 400, y: 50 },
        draggable: false
      };

      const stepNodes = goal.steps.map((step, index) => ({
        id: step.id,
        type: 'goalStep',
        data: { 
          label: step.title,
          description: step.description,
          status: step.status,
          taskId: step.taskId,
          priority: step.priority || 1,
          dueDate: step.dueDate,
          goalId: goal.id
        },
        position: step.position || { 
          x: 200 + (index % 3) * 300,
          y: 250 + Math.floor(index / 3) * 150
        },
        draggable: true
      }));

      const stepEdges = goal.steps.map((step, index) => ({
        id: `e-main-${index}`,
        source: `goal-${goal.id}`,
        target: step.id,
        type: 'smoothstep',
        animated: step.status === 'completed',
        style: { stroke: '#94A3B8' }
      }));

      newNodesMap[goal.id] = [mainNode, ...stepNodes];
      newEdgesMap[goal.id] = stepEdges;
    });

    setNodesMap(newNodesMap);
    setEdgesMap(newEdgesMap);
  }, [goals]);

  const handleGoalCreated = async () => {
    setShowNewGoalModal(false);
    await loadGoals();
  };

  const handleDeleteGoal = async (goalId) => {
    try {
      await goalService.deleteGoal(goalId);
      await loadGoals();
      setShowDeleteConfirm(false);
      setGoalToDelete(null);
    } catch (error) {
      console.error('Error deleting goal:', error);
    }
  };

  const onNodesChange = (changes, goalId) => {
    setNodesMap(prev => ({
      ...prev,
      [goalId]: applyNodeChanges(changes, prev[goalId] || [])
    }));
  };

  const onEdgesChange = (changes, goalId) => {
    setEdgesMap(prev => ({
      ...prev,
      [goalId]: applyEdgeChanges(changes, prev[goalId] || [])
    }));
  };

  const handleNodeDragEnd = async (event, node, goal) => {
    if (node.id.startsWith('goal-')) return;

    const stepData = goal.steps.find(step => step.id === node.id);
    if (!stepData || !stepData.taskId) return;

    try {
      const goalRef = doc(db, 'goals', goal.id);
      const goalDoc = await getDoc(goalRef);
      if (goalDoc.exists()) {
        const updatedSteps = goalDoc.data().steps.map(step => {
          if (step.id === node.id) {
            return {
              ...step,
              position: node.position
            };
          }
          return step;
        });

        await updateDoc(goalRef, { steps: updatedSteps });
      }
    } catch (error) {
      console.error('Error updating node position:', error);
    }
  };

  const handleAddNode = async (nodeData, goalId) => {
    try {
      const goalRef = doc(db, 'goals', goalId);
      const goalDoc = await getDoc(goalRef);
      
      if (goalDoc.exists()) {
        const goal = goalDoc.data();
        const newStepId = `step-${goal.steps.length}`;
        
        // Create new task for the step
        const taskData = {
          title: nodeData.title,
          description: nodeData.description,
          category: 'todo',
          createdBy: userProfile.id,
          teamId,
          createdAt: serverTimestamp(),
          status: 'pending',
          tags: [`#goal-${goalId}`],
          goalId,
          goalStepId: newStepId,
          note: '',
          replies: []
        };

        const taskRef = await addDoc(collection(db, 'tasks'), taskData);

        // Add new step to goal
        const newStep = {
          id: newStepId,
          title: nodeData.title,
          description: nodeData.description,
          order: goal.steps.length,
          status: 'pending',
          taskId: taskRef.id,
          position: { x: 400, y: 250 + goal.steps.length * 100 }
        };

        await updateDoc(goalRef, {
          steps: [...goal.steps, newStep]
        });

        await loadGoals();
        setShowAddNodeModal(false);
        setSelectedGoalForNode(null);
      }
    } catch (error) {
      console.error('Error adding node:', error);
    }
  };

  const handleTaskVisibilityChange = async (goal, isVisible) => {
    try {
      // Get all tasks for this goal
      const tasksQuery = query(collection(db, 'tasks'), where('goalId', '==', goal.id));
      const taskSnapshot = await getDocs(tasksQuery);
      
      // Update each task's visibility
      const batch = writeBatch(db);
      taskSnapshot.docs.forEach((doc) => {
        batch.update(doc.ref, {
          hidden: !isVisible
        });
      });
      await batch.commit();
    } catch (error) {
      console.error('Error updating task visibility:', error);
    }
  };

  const handleStepUpdate = async (stepId, goalId, updates) => {
    try {
      const goalRef = doc(db, 'goals', goalId);
      const goalDoc = await getDoc(goalRef);
      
      if (goalDoc.exists()) {
        const updatedSteps = goalDoc.data().steps.map(step => {
          if (step.id === stepId) {
            return {
              ...step,
              ...updates
            };
          }
          return step;
        });

        await updateDoc(goalRef, { steps: updatedSteps });
        
        // Reload goals to refresh the UI
        await loadGoals();
      }
    } catch (error) {
      console.error('Error updating step:', error);
    }
  };

  const renderGoalFlow = (goal) => {
    return (
      <div key={goal.id} className="mb-8 bg-white/80 backdrop-blur-lg rounded-lg p-6 shadow-lg relative">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-lg font-semibold">{goal.title}</h3>
            <p className="text-sm text-gray-600">{goal.description}</p>
            <div className="mt-2 text-sm">
              <span className="text-gray-500">Deadline:</span>
              <span className="ml-2">{new Date(goal.deadline).toLocaleDateString()}</span>
            </div>
          </div>

          <div className="flex flex-col items-end gap-4">
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  setSelectedGoalForNode(goal);
                  setShowAddNodeModal(true);
                }}
                className="px-2 py-1 text-sm bg-teal-600 text-white rounded-md hover:bg-teal-700"
              >
                Add Step
              </button>
              <Menu as="div" className="relative">
                <Menu.Button className="p-1 hover:bg-gray-100 rounded-full transition-colors">
                  <FontAwesomeIcon icon={faEllipsisVertical} className="w-4 h-4 text-gray-500" />
                </Menu.Button>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-red-50 text-red-700' : 'text-red-600'
                          } group flex w-full items-center px-4 py-2 text-sm`}
                          onClick={() => {
                            setGoalToDelete(goal);
                            setShowDeleteConfirm(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} className="mr-3 h-4 w-4" />
                          Delete Goal
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>

            <div className="flex items-center gap-2">
              <Switch
                checked={showAsTasks}
                onChange={(checked) => {
                  setShowAsTasks(checked);
                  handleTaskVisibilityChange(goal, checked);
                }}
                className={`${
                  showAsTasks ? 'bg-teal-600' : 'bg-gray-200'
                } relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2`}
              >
                <span
                  className={`${
                    showAsTasks ? 'translate-x-5' : 'translate-x-1'
                  } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
                />
              </Switch>
              <span className="text-sm text-gray-600">Show as tasks</span>
            </div>
          </div>
        </div>

        <div className="h-[500px] border border-gray-200 rounded-lg">
          <ReactFlow
            nodes={nodesMap[goal.id] || []}
            edges={edgesMap[goal.id] || []}
            nodeTypes={nodeTypes}
            onNodesChange={(changes) => onNodesChange(changes, goal.id)}
            onEdgesChange={(changes) => onEdgesChange(changes, goal.id)}
            onNodeDragStop={(event, node) => handleNodeDragEnd(event, node, goal)}
            fitView
            className="bg-gray-50/50"
            minZoom={0.5}
            maxZoom={1.5}
            nodesDraggable={true}
            nodesConnectable={false}
            elementsSelectable={true}
            snapToGrid={true}
            snapGrid={[20, 20]}
            defaultViewport={{ x: 0, y: 0, zoom: 1 }}
          >
            {/* Add visual grid */}
            <div className="absolute inset-0 pointer-events-none">
              <div 
                className="w-full h-full"
                style={{
                  backgroundImage: 'radial-gradient(circle at 1px 1px, #e5e7eb 1px, transparent 1px)',
                  backgroundSize: '20px 20px'
                }}
              />
            </div>
          </ReactFlow>
        </div>

        <div className="portal-container" style={{ position: 'relative', zIndex: 1000 }} />
      </div>
    );
  };

  const handleBackToTaskboard = () => {
    // Get the current teamId from the URL
    const params = new URLSearchParams(location.search);
    const currentTeamId = params.get('teamId');
    
    if (currentTeamId) {
      navigate(`/?teamId=${currentTeamId}`, { replace: true });
    } else {
      navigate('/');
    }
  };

  if (!teamId) {
    return (
      <div className="max-w-6xl mx-auto p-6">
        <div className="text-center py-12">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">No Team Selected</h2>
          <p className="text-gray-600">Please select a team to view its goals.</p>
          <Link 
            to="/" 
            className="mt-4 inline-block px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700"
          >
            Go to Teams
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-6">
          <button 
            onClick={handleBackToTaskboard}
            className="text-gray-600 hover:text-gray-900 flex items-center gap-2 text-sm"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="w-3 h-3" />
            Back to Taskboard
          </button>
          <h2 className="text-2xl font-bold">Goals</h2>
        </div>
        <button
          onClick={() => setShowNewGoalModal(true)}
          className="px-4 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-700"
        >
          New Goal
        </button>
      </div>

      {loading ? (
        <div>Loading goals...</div>
      ) : (
        <div className="space-y-6">
          {goals.map(renderGoalFlow)}
        </div>
      )}

      <NewGoalModal 
        isOpen={showNewGoalModal}
        onClose={() => setShowNewGoalModal(false)}
        onSuccess={handleGoalCreated}
        teamId={teamId}
      />

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && goalToDelete && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white/80 backdrop-blur-lg rounded-lg p-6 w-full max-w-md mx-4">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Delete Goal</h3>
            <p className="text-sm text-gray-600 mb-4">
              Are you sure you want to delete "{goalToDelete.title}"? This will also delete all associated tasks and cannot be undone.
            </p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => {
                  setShowDeleteConfirm(false);
                  setGoalToDelete(null);
                }}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDeleteGoal(goalToDelete.id)}
                className="px-4 py-2 text-sm bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add Node Modal */}
      {showAddNodeModal && selectedGoalForNode && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white/80 backdrop-blur-lg rounded-lg p-6 w-full max-w-md mx-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold text-gray-800">Add New Step</h3>
              <button 
                onClick={() => {
                  setShowAddNodeModal(false);
                  setSelectedGoalForNode(null);
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                ×
              </button>
            </div>

            <form 
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                handleAddNode(
                  {
                    title: formData.get('title'),
                    description: formData.get('description')
                  },
                  selectedGoalForNode.id
                );
              }}
              className="space-y-4"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Step Title
                </label>
                <input
                  name="title"
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  name="description"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg bg-white/50 focus:outline-none focus:ring-1 focus:ring-teal-500"
                  rows={3}
                />
              </div>

              <div className="flex justify-end gap-2 pt-4">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddNodeModal(false);
                    setSelectedGoalForNode(null);
                  }}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm bg-teal-600 text-white rounded-md hover:bg-teal-700"
                >
                  Add Step
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
} 