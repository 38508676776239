import { db } from './firebase';
import { 
  collection, 
  addDoc, 
  doc, 
  updateDoc, 
  deleteDoc, 
  getDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  writeBatch
} from 'firebase/firestore';

export const goalService = {
  async createGoal(data) {
    try {
      const goalData = {
        title: data.title,
        description: data.description,
        deadline: data.deadline,
        teamId: data.teamId,
        createdBy: data.userId,
        createdAt: serverTimestamp(),
        status: 'in-progress',
        steps: data.steps.map((step, index) => ({
          id: `step-${index}`,
          title: step.title,
          description: step.description,
          order: index,
          status: 'pending',
          taskId: null // Will be populated when task is created
        })),
        progress: 0
      };

      const goalRef = await addDoc(collection(db, 'goals'), goalData);

      // Create tasks for each step
      const tasks = await Promise.all(data.steps.map(async (step, index) => {
        const taskData = {
          title: step.title,
          description: step.description,
          category: 'todo',
          createdBy: data.userId,
          teamId: data.teamId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          status: 'pending',
          tags: ['#goal'],
          goalId: goalRef.id,
          goalStepId: `step-${index}`,
          note: '',
          replies: [],
          position: 999999,
          dueDate: step.dueDate || null
        };

        const taskRef = await addDoc(collection(db, 'tasks'), taskData);
        return { stepId: `step-${index}`, taskId: taskRef.id };
      }));

      // Update goal with task IDs
      const updatedSteps = goalData.steps.map((step, index) => ({
        ...step,
        taskId: tasks.find(t => t.stepId === step.id)?.taskId
      }));

      await updateDoc(doc(db, 'goals', goalRef.id), { steps: updatedSteps });

      return { id: goalRef.id, ...goalData };
    } catch (error) {
      console.error('Error creating goal:', error);
      throw error;
    }
  },

  async getTeamGoals(teamId) {
    try {
      const q = query(collection(db, 'goals'), where('teamId', '==', teamId));
      const snapshot = await getDocs(q);
      return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting team goals:', error);
      throw error;
    }
  },

  async updateGoalProgress(goalId, progress) {
    try {
      await updateDoc(doc(db, 'goals', goalId), { progress });
    } catch (error) {
      console.error('Error updating goal progress:', error);
      throw error;
    }
  },

  async deleteGoal(goalId) {
    try {
      // Delete the goal document
      await deleteDoc(doc(db, 'goals', goalId));

      // Get all tasks with this goalId
      const tasksQuery = query(collection(db, 'tasks'), where('goalId', '==', goalId));
      const taskSnapshot = await getDocs(tasksQuery);

      // Delete all associated tasks
      const batch = writeBatch(db);
      taskSnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

    } catch (error) {
      console.error('Error deleting goal:', error);
      throw error;
    }
  }
}; 